<script>
import { mapGetters } from 'vuex'
import IconClose from '../assets/icon-close.vue'
import IconUpload from '../assets/icon-upload.vue'
import IconFile from '../assets/icon-file.vue'
import { IMPORT_FILE, UPLOAD_DOC } from '../store/actions'
import CustomCard from '../components/CustomCard'
import MoreInfo from '../assets/more-info'

export default {
  name: 'InBulkModal',
  components: {
    IconClose,
    IconUpload,
    IconFile,
    CustomCard,
    MoreInfo,
  },
  props: {
    frontendProps: {},
    excelTemplate: {},
    tid: {
      required: true,
    },
    caName: {
      required: true,
    },
    credName: {
      required: true,
    },
    urlPhoto: {
      required: true,
    },
    cardHeaders: {},
  },
  emits: ['close'],
  data() {
    return {
      step: 0,
      password: '',
      file: null,
      importing: false,
      headers: [],
      templateTable: [],
      templateValues: [],
      tableValues: [],
      showConfirmData: false,
      highlight: false,
      email: null,
      sending: false,
      buttonText: this.$t('button.next'),
      errorMsg: false,
    }
  },
  computed: {
    ...mapGetters(['photoURL']),
  },
  created() {
    this.$log.debug('Issue in Bulk Modal: ', this.tid)
  },
  mounted() {
    // Determine if drag and drop functionality is capable in the browser
    this.dragAndDropCapable = this.determineDragAndDropCapable()

    if (this.dragAndDropCapable) {
      /*
      Listen to all of the drag events and bind an event listener to each
      for the fileform.
    */
      [
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop',
      ].forEach(
        (evt) => {
          this.$refs.fileform.addEventListener(
            evt,
            (e) => {
              e.preventDefault()
              e.stopPropagation()
            },
            false,
          )
        },
      )

      this.$refs.fileform.addEventListener(
        'drop',
        (e) => {
          const files = e.dataTransfer.files
          this.uploadFiles(files)
          // for (let i = 0; i < e.dataTransfer.files.length; i++) {
          //   this.$store.commit("ADD_FILE", e.dataTransfer.files);
          // }
          this.highlight = false
        },
      )
    }
  },
  methods: {
    donwloadTemplate() {
      window.open(this.excelTemplate)
    },
    handleFileUpload() {
      this.uploadFiles(this.$refs.pdf.files)
      const input = this.$refs.pdf
      input.type = 'text'
      input.type = 'file'
    },
    back() {
      this.showConfirmData = false
      this.templateValues = []
      this.templateTable = []
      this.headers = []
    },
    next() {
      if (this.showConfirmData) { this.import() }
      else { this.upload() }
    },
    upload() {
      const formData = new FormData()

      formData.append('uploadfile', this.file)
      formData.append('tid', this.tid)

      this.$log.debug('upload: ', UPLOAD_DOC)

      this.$store
        .dispatch(UPLOAD_DOC, { formData })
        .then((res) => {
          // self.$router.push("/verify");
          this.import_data = res.data.data

          this.tableValues = this.import_data[0].table_values
          console.log('tableValues', this.tableValues)

          let index = 0
          for (const [key, value] of Object.entries(
            this.import_data[0].user_data,
          )) {
            this.headers.push({
              text: key,
              align: 'start',
              value: key,
              width: 150,
            })
            this.templateValues.push({ attr: key, value, index })
            index++
          }

          let i = 0
          if (this.tableValues) {
            for (const t of this.tableValues) {
              for ([key, value] of Object.entries(t)) {
                const el = {
                  text: key,
                  align: 'start',
                  value: key + i,
                  width: 150,
                }
                el[key] = value
                this.headers.push(el)
              }
              i++
            }
          }
          console.log('this.import_data', this.import_data)

          for (const e of this.import_data) {
            const entry = {}

            for ([key, value] of Object.entries(e.user_data)) { entry[key] = value }

            let i = 0
            if (e.table_values) {
              for (t of e.table_values) {
                for ([key, value] of Object.entries(t)) { entry[key + i] = value }

                i++
              }
            }
            console.log(entry)
            this.templateTable.push(entry)
          }
          console.log(this.templateTable)

          this.showConfirmData = true

          this.buttonText = this.$t('button.confirm')
        })
        .catch((err) => {
          console.error('Error Emiting File:', err)
          // TO DO :: remove file from list
          this.errorMsg = this.$t('inBulkModal.error')
        })
    },
    import() {
      this.importing = true
      this.$store
        .dispatch(IMPORT_FILE, { tid: this.tid, import_data: this.import_data })
        .then(() => {
          // self.$router.push("/verify");

          this.close()
        })
        .catch((err) => {
          this.importing = false
          console.error('Error Emiting File:', err)
          this.errorMsg = this.$t('inBulkModal.error')
          // TO DO :: remove file from list
        })
    },
    uploadFiles(f) {
      this.errorMsg = null
      const loadFiles = (file) => {
        this.file = file
        setTimeout(() => {
          const wrapper = document.getElementById('scroll')
          wrapper.scrollBy({ top: 200, left: 0, behavior: 'smooth' })
        }, 200)
      }

      for (let i = 0; i < f.length; i++) {
        console.log(f[i])
        const ext = f[i].name.match(/\.([^.]+)$/)[1]
        switch (ext) {
          case 'csv':
            loadFiles(f[i])
            break
          default:
            console.error('Rejected file type ', f[i].name)
            this.errorMsg = this.$t('inBulkModal.errorFile')
        }
      }
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div')

      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window
        && 'FileReader' in window
      )
    },
    openFileSelection() {
      this.$refs.pdf.click()
    },
    onDragOver(event) {
      event.preventDefault()

      this.highlight = true
    },
    onDragLeave(event) {
      event.preventDefault()

      this.highlight = false
    },
    close() {
      this.$emit('close')
    },
  },

}
</script>

<template>
  <transition name="modal-fade">
    <div v-show="true" class="modal-backdrop">
      <div
        class="modal-wallid"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header id="modalTitle" class="modal-header">
          <slot name="header">
            {{ $t('issue.title') }}
            <v-spacer />
            <button
              type="button"
              class="btn-close"
              aria-label="Close modal"
              @click="close"
            >
              <IconClose />
            </button>
          </slot>
        </header>

        <div id="scroll" class="modal-body scroll">
          <slot name="body">
            <v-container class="">
              <v-row v-if="!showConfirmData">
                <v-col cols="12" class="pb-0">
                  <p class="subtitle_text">
                    {{ $t('inBulkModal.text1') }}
                  </p>
                  <p class="subtitle_text mb-0">
                    {{ $t('inBulkModal.text2') }}
                  </p>
                </v-col>
                <v-col id="file-drag-drop" cols="12" class="pt-0">
                  <input
                    id="pdf"
                    ref="pdf"
                    type="file"
                    style="visibility: hidden; width: 0px; height: 0px"
                    accept=".csv"
                    multiple="multiple"
                    @change="handleFileUpload()"
                  >

                  <div
                    class="outside-box"
                    :class="{ 'error-upload': errorMsg }"
                    @click="openFileSelection"
                  >
                    <form
                      ref="fileform"
                      class="drag-drop-form"
                      :class="{ highlight }"
                      style="cursor: pointer"
                      @dragover="onDragOver"
                      @dragleave="onDragLeave"
                    >
                      <div style="display: flex">
                        <IconUpload class="mx-auto" />
                      </div>
                      <div>
                        <p class="drop-files drag-box-text mx-auto my-0">
                          {{ $t('inBulkModal.upload[0]') }}
                          <label style="cursor: pointer" class="browse-file">{{
                            $t('inBulkModal.upload[1]')
                          }}</label>
                          {{ $t('inBulkModal.upload[2]') }}
                        </p>
                      </div>
                    </form>
                  </div>

                  <p
                    v-if="errorMsg"
                    class="light_text text-center mt-2 error-upload"
                  >
                    {{ errorMsg }}
                  </p>
                  <p v-else class="light_text text-center mt-2">
                    {{ $t('inBulkModal.hint') }}
                  </p>
                </v-col>

                <v-col v-if="file" cols="12" class="file-listing pl-4">
                  <div>
                    <IconFile class="mr-2" />
                    <p>{{ file.name }}</p>
                    <v-spacer />
                    <button
                      type="button"
                      class="btn-close"
                      aria-label="Close modal"
                      @click="file = null"
                    >
                      <IconClose />
                    </button>
                  </div>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" class="pb-0">
                  <p class="subtitle_text">
                    {{ $t('inBulkModal.text3') }}
                  </p>
                </v-col>
                <v-col cols="12" md="12" class="pb-1">
                  <CustomCard
                    :front-template="templateValues"
                    :back-template="cardHeaders"
                    :tableValues="tableValues"
                    :template-values="templateValues"
                    :frontend-props="frontendProps"
                    :ca-name="caName"
                    :credential-name="credName"
                    :url-photo="urlPhoto"
                    :height="267"
                    :width="378"
                  />
                </v-col>
                <v-col cols="12" md="12" class="pb-1">
                  <v-data-table
                    class="table_in_bulk"
                    :headers="headers"
                    :items="templateTable"
                    :no-results-text="$t('table.noData')"
                    :items-per-page="10"
                    :hide-default-footer="templateTable.length < 10"
                  >
                    <template #body="{ items }">
                      <tbody>
                        <tr v-for="(row, indexR) in items" :key="indexR">
                          <td v-for="(item, indexL) in row" :key="indexL">
                            <p>
                              {{ reduceText(item, 30)[0] }}
                              <v-tooltip
                                v-if="reduceText(item, 30).length > 1"
                                bottom
                                content-class="tooltip-desc"
                              >
                                <template #activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <MoreInfo />
                                  </span>
                                </template>
                                <p>{{ item }}</p>
                              </v-tooltip>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <v-row class="justify-end" no-gutters>
              <v-btn
                v-if="!showConfirmData"
                class="back"
                @click="donwloadTemplate()"
              >
                {{ $t('button.download') }}
              </v-btn>
              <v-spacer />
              <v-btn v-if="showConfirmData" class="back mr-4" @click="back()">
                {{
                  $t('button.back')
                }}
              </v-btn>
              <v-btn
                class="next"
                :loading="importing"
                :disabled="importing || !file"
                @click="next()"
              >
                {{ buttonText }}
              </v-btn>
            </v-row>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.modal-wallid {
  .error-upload {
    &.outside-box {
      border-color: var(--coral);
    }
    &.light_text {
      margin: 0 auto;
      max-width: 60%;
      color: var(--coral) !important;
    }
  }
  .table_in_bulk {
    & > .v-data-table__wrapper > table > tbody > tr > td {
      font-size: 12px;
      padding-right: 0px;
    }
  }
  width: 100%;
  .file-listing {
    border-radius: 4px;
    border: solid 1px #b8b9bb;
    div {
      display: flex;
      p {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: var(--charcoal-grey);
        margin-bottom: 0;
      }
    }
  }
  .outside-box {
    border: dashed 1px #b8b9bb;
    border-radius: 4px;
    .highlight {
      background-color: #eaf2f3 !important;
    }
    .drag-drop-form {
      padding: 50px 100px 30px;
    }
    .drag-box-text {
      max-width: 200px;
      margin-top: 22px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--teal-blue);
    }
  }
  .modal-body.scroll {
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .static_image {
    min-width: 380px;
    .id-card {
      position: absolute;
    }
  }
  .input-field {
    .v-input--radio-group {
      .v-input__slot {
        border: none !important;
      }
    }
    .v-input__control {
      min-height: unset;
      .v-input__slot {
        margin-bottom: 4px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px var(--light-grey-blue);
      }
      .v-text-field__details {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
